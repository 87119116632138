import 'moment/locale/en-gb';
import 'moment/locale/nl';

import { AutomationAcceptTranslationInputI18n } from 'components/HookForm/Entity/Automation/AcceptTranslation/I18n';
import { AutomationActiveInputI18n } from 'components/HookForm/Entity/Automation/Active/I18n';
import { AutomationDelayInputI18n } from 'components/HookForm/Entity/Automation/Delay/I18n';
import { AutomationDescriptionInputI18n } from 'components/HookForm/Entity/Automation/Description/I18n';
import { AutomationMarkForTranslationInputI18n } from 'components/HookForm/Entity/Automation/MarkForTranslation/I18n';
import { AutomationNameInputI18n } from 'components/HookForm/Entity/Automation/Name/I18n';
import { AutomationPathInputI18n } from 'components/HookForm/Entity/Automation/Paths/I18n';
import { AutomationPeriodInputI18n } from 'components/HookForm/Entity/Automation/Period/I18n';
import { AutomationRecursiveCrawlInputI18n } from 'components/HookForm/Entity/Automation/RecursiveCrawl/I18n';
import { CmsSelectI18n } from 'components/HookForm/Entity/Common/Cms/I18n';
import { ProjectSelectI18n } from 'components/HookForm/Entity/Common/Project/I18n';
import { SourceLanguageSelectI18n } from 'components/HookForm/Entity/Common/SourceLanguage/I18n';
import { SourceLanguageFilterSelectI18n } from 'components/HookForm/Entity/Common/SourceLanguageFilter/I18n';
import { TargetLanguagesSelectI18n } from 'components/HookForm/Entity/Common/TargetLanguages/I18n';
import { JobNameInputI18n } from 'components/HookForm/Entity/Job/Name/I18n';
import { CancelInputI18n } from 'components/HookForm/Input/Cancel/I18n';
import { ResetInputI18n } from 'components/HookForm/Input/Reset/I18n';
import { SubmitInputI18n } from 'components/HookForm/Input/Submit/I18n';
import i18n, { ResourceLanguage } from 'i18next';
import BrowserDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { AutomationCreatePageI18n } from 'pages/Administration/AutomationCreatePage/I18n';
import { AutomationEditPageI18n } from 'pages/Administration/AutomationEditPage/I18n';
import { JobEditPageI18n } from 'pages/Overview/JobEditPage/I18n';
import { initReactI18next } from 'react-i18next';

import { ResourceLocalization, isResourceLocalization } from './AppLocalization';
import { actions, errors, locale, notifications, pages } from './language';

export interface Translatable {
  i18nKey: string;
  i18nArgs?: object;
}

function getResources(resources: object, language: keyof ResourceLocalization): ResourceLanguage {
  const result: ResourceLanguage = {};

  Object.entries(resources).forEach(([key, resource]): void => {
    if (isResourceLocalization(resource)) {
      result[key] = resource[language] || key;
    } else {
      result[key] = getResources(resource, language);
    }
  });
  return result;
}

const localization = {
  ...pages,
  errors,
  notification: notifications,
  locale,
  actions,
  [AutomationRecursiveCrawlInputI18n.configuration.namespace]: AutomationRecursiveCrawlInputI18n.translations,
  [AutomationAcceptTranslationInputI18n.configuration.namespace]: AutomationAcceptTranslationInputI18n.translations,
  [AutomationMarkForTranslationInputI18n.configuration.namespace]: AutomationMarkForTranslationInputI18n.translations,
  [AutomationDescriptionInputI18n.configuration.namespace]: AutomationDescriptionInputI18n.translations,
  [AutomationActiveInputI18n.configuration.namespace]: AutomationActiveInputI18n.translations,
  [AutomationPeriodInputI18n.configuration.namespace]: AutomationPeriodInputI18n.translations,
  [AutomationDelayInputI18n.configuration.namespace]: AutomationDelayInputI18n.translations,
  [AutomationNameInputI18n.configuration.namespace]: AutomationNameInputI18n.translations,
  [ProjectSelectI18n.configuration.namespace]: ProjectSelectI18n.translations,
  [SourceLanguageFilterSelectI18n.configuration.namespace]: SourceLanguageFilterSelectI18n.translations,
  [SourceLanguageSelectI18n.configuration.namespace]: SourceLanguageSelectI18n.translations,
  [CmsSelectI18n.configuration.namespace]: CmsSelectI18n.translations,
  [AutomationEditPageI18n.configuration.namespace]: AutomationEditPageI18n.translations,
  [AutomationPathInputI18n.configuration.namespace]: AutomationPathInputI18n.translations,
  [TargetLanguagesSelectI18n.configuration.namespace]: TargetLanguagesSelectI18n.translations,
  [SubmitInputI18n.configuration.namespace]: SubmitInputI18n.translations,
  [ResetInputI18n.configuration.namespace]: ResetInputI18n.translations,
  [CancelInputI18n.configuration.namespace]: CancelInputI18n.translations,
  [JobEditPageI18n.configuration.namespace]: JobEditPageI18n.translations,
  [AutomationCreatePageI18n.configuration.namespace]: AutomationCreatePageI18n.translations,
  [JobNameInputI18n.configuration.namespace]: JobNameInputI18n.translations,
};

i18n
  .use(BrowserDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      // no need, react handles escaping
      escapeValue: false,
      // format dates using moment
      format: (value, format): string => {
        if (typeof value === 'number') {
          if (format === 'hours') {
            return moment.duration(value, 'hours').humanize();
          } else if (format === 'minutes') {
            return moment.duration(value, 'minutes').humanize();
          }
        }
        if (value instanceof Date) {
          if (format === 'fromNow') {
            return moment(value).fromNow();
          } else {
            return moment(value).format(format);
          }
        }
        return value;
      },
    },
    resources: {
      en: {
        translation: getResources(localization, 'en'),
      },
      nl: {
        translation: getResources(localization, 'nl'),
      },
    },
  });

// keep the moment locale in sync with i18n
i18n.on('languageChanged', (lng): void => {
  moment.locale(lng);
});

export default i18n;
