import React, { useState } from 'react';

import { useAsyncState } from '../../../common/hooks/useAsyncState';
import { AppLayout } from '../../../components/AppLayout/AppLayout';
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs';
import { Tile } from '../../../components/Tile/Tile';
import { Api } from '../../../modules/api/api';
import { HomePageCrumb } from '../HomePage/HomePage';

export const HelpPageCrumb: Breadcrumb = {
  page: 'Overview.HelpPage',
  icon: 'question-circle',
  location: '/help',
};

export const HelpPage: React.FC = ReactElement => {
  const [user] = useState(Api.getUserDataFromCache);
  const isAuthenticated = !!user;
  const [version] = useAsyncState(Api.locHub.getVersion);

  // Styles
  const versionStyle: React.CSSProperties = {
    marginLeft: '1%',
    fontWeight: 'bold',
  };

  return (
    <AppLayout showItems={isAuthenticated} breadcrumbs={[HomePageCrumb, HelpPageCrumb]}>
      <div className="tile is-vertical">
        <div className="tile">
          <Tile
            i18nKey={'Overview.LocHubManualPage'}
            location={'/guide/user/index.html'}
            icon={'book'}
            id={'lochub-manual-tile'}
          />
          <Tile
            i18nKey={'Overview.TapiccManualPage'}
            location={'/guide/tapicc/index.html'}
            icon={'book'}
            id={'tapicc-manual-tile'}
          />
          <Tile
            i18nKey={'Overview.XillioReferencePage'}
            location={'/xillio/docs'}
            icon={'book'}
            id={'xillio-reference-tile'}
          />
        </div>
      </div>
      <div className="tile">
        <Tile
          i18nKey={'Overview.LocHubSwaggerPage'}
          location={'/swagger-ui.html?urls.primaryName=LocHub'}
          icon={'file-code'}
          id={'lochub-swagger-tile'}
        />
        <Tile
          i18nKey={'Overview.Tapicc007SwaggerPage'}
          location={'/swagger-ui.html?urls.primaryName=TAPICC%200.0.7'}
          icon={'file-code'}
          id={'tapicc-0.0.7-swagger-tile'}
        />
        <Tile
          i18nKey={'Overview.Tapicc100BetaSwaggerPage'}
          location={'/swagger-ui.html?urls.primaryName=TAPICC%201.0.0-beta'}
          icon={'file-code'}
          id={'tapicc-1.0.0-beta-swagger-tile'}
        />
      </div>
      <h1 style={versionStyle}>LocHub Version {version}</h1>
    </AppLayout>
  );
};
