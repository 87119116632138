import { classNames } from 'common/utils/classNames';
import logo from 'images/logo-white.png';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const LandingLayout: React.FC<{ id?: string }> = ({ id, children }): ReactElement => {
  const [t] = useTranslation();
  return (
    <div className="landing-page">
      <div className="welcome">
        <img alt="LocHub" className="logo" src={logo} />
        <p>{t('Overview.Landing.introductionMessage')}</p>
      </div>
      <div className="content">
        <h2>
          {t('Overview.Landing.title')} <span>LocHub</span>
        </h2>
        <div className={classNames('box', id)}>
          <div className="container">{children}</div>
        </div>
        <p>
          {t('Overview.Landing.helpMessage')} <a href="/#/help"> {t('Overview.Landing.helpLink')}</a>.
        </p>
      </div>
    </div>
  );
};
