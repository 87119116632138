import { CmsConfiguration, XillioEntity } from 'common/interfaces/xillio';

import { XillioConnectors } from './connectors/connectors';
import { XillioEntities } from './entities/entities';

export class XillioApi {
  public static readonly entities = XillioEntities;
  public static readonly connectors = XillioConnectors;

  public static async getConfigurations(): Promise<CmsConfiguration[]> {
    return (await XillioApi.entities.getAll()).map((xillioEntity: XillioEntity) => {
      return {
        configurationType: xillioEntity.kind,
        id: xillioEntity.configurationId,
        name: xillioEntity.original.name.displayName ?? xillioEntity.kind,
      };
    });
  }
}
