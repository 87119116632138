import { XillioConnector } from 'common/interfaces/xillio';

export enum XillioConnectorType {
  AEM = 'Aem',
  ALFRESCO = 'Alfresco',
  BITBUCKET = 'Bitbucket',
  BOX = 'Box',
  CONFLUENCE = 'Confluence',
  DOCUMENTUM = 'Documentum',
  DROPBOX = 'Dropbox',
  DRUPAL = 'Drupal',
  EGNYTE = 'Egnyte',
  EPISERVER_10 = 'Episerver10',
  FILENET = 'Filenet',
  GITLAB = 'GitLab',
  GOOGLE_DRIVE = 'GoogleDrive',
  JSON_RPC = 'JsonRpc',
  LIFERAY = 'Liferay',
  NUXEO = 'Nuxeo',
  ONE_DRIVE = 'OneDrive',
  OPENTEXT = 'Opentext',
  S3 = 'S3',
  SALESFORCE = 'Salesforce',
  SHARE_POINT = 'SharePoint',
  SITECORE = 'Sitecore',
  TRIM = 'Trim',
  WORDPRESS = 'WordPress',
  ZENDESK = 'Zendesk',
}

export class XillioConnectors {
  private static connectors = new Map<XillioConnectorType, XillioConnector>([
    [
      XillioConnectorType.AEM,
      {
        configurationType: XillioConnectorType.AEM,
        translationScopeSupport: false,
        description:
          'Adobe Experience Manager brings your content management system (CMS) and digital asset management (DAM) together — in one place.',
      },
    ],
    [
      XillioConnectorType.ALFRESCO,
      {
        configurationType: XillioConnectorType.ALFRESCO,
        translationScopeSupport: false,
        description:
          'The Alfresco Digital Business Platform is an open, modern, secure platform that intelligently activates process and content to accelerate the flow of business. It provides the fastest path for people to interact with information and for companies to respond to changing business needs.',
      },
    ],
    [
      XillioConnectorType.BITBUCKET,
      {
        configurationType: XillioConnectorType.BITBUCKET,
        translationScopeSupport: false,
        description:
          'Bitbucket is a web-based version control repository hosting service for source code and development projects.',
      },
    ],
    [
      XillioConnectorType.BOX,
      {
        configurationType: XillioConnectorType.BOX,
        translationScopeSupport: false,
        description:
          'Box Drive is the incredibly simple way to stream all of your files — even millions of files — right to your desktop, taking up very little hard drive space.',
      },
    ],
    [
      XillioConnectorType.CONFLUENCE,
      {
        configurationType: XillioConnectorType.CONFLUENCE,
        translationScopeSupport: false,
        description: 'Confluence is content collaboration software that changes how modern teams work.',
      },
    ],
    [
      XillioConnectorType.DOCUMENTUM,
      {
        configurationType: 'Documentum',
        translationScopeSupport: false,
        description:
          'For over 25 years, content has been critical to the operations of OpenText Documentum customers. Our customers have some of the most demanding environments and require a complete content strategy.',
      },
    ],
    [
      XillioConnectorType.DROPBOX,
      {
        configurationType: XillioConnectorType.DROPBOX,
        translationScopeSupport: false,
        description:
          'Dropbox is a modern workspace designed to reduce busywork—so you can focus on the things that matter.',
      },
    ],
    [
      XillioConnectorType.DRUPAL,
      {
        configurationType: XillioConnectorType.DRUPAL,
        translationScopeSupport: true,
        description:
          "Drupal is an open source platform for building amazing digital experiences. It's made by a dedicated community. Anyone can use it, and it will always be free.",
      },
    ],
    [
      XillioConnectorType.EGNYTE,
      {
        configurationType: XillioConnectorType.EGNYTE,
        translationScopeSupport: false,
        description: 'Egnyte CONNECT: Secure File Sharing & Collaboration.',
      },
    ],
    [
      XillioConnectorType.EPISERVER_10,
      {
        configurationType: XillioConnectorType.EPISERVER_10,
        translationScopeSupport: true,
        description:
          'Episerver is a global software company offering web content management (WCM) (or CMS), digital commerce, and digital marketing, through the Episerver Digital Experience Cloud software platform.',
      },
    ],
    [
      XillioConnectorType.FILENET,
      {
        configurationType: XillioConnectorType.FILENET,
        translationScopeSupport: false,
        description:
          'IBM FileNet Content Manager is document management engine with enterprise content, security and storage features plus process management capabilities.',
      },
    ],
    [
      XillioConnectorType.GITLAB,
      {
        configurationType: XillioConnectorType.GITLAB,
        translationScopeSupport: false,
        description:
          'GitLab is a web-based Git-repository manager providing wiki, issue-tracking and CI/CD pipeline features.',
      },
    ],
    [
      XillioConnectorType.GOOGLE_DRIVE,
      {
        configurationType: XillioConnectorType.GOOGLE_DRIVE,
        translationScopeSupport: false,
        description: 'A safe place for all your files.',
      },
    ],
    [
      XillioConnectorType.JSON_RPC,
      {
        configurationType: XillioConnectorType.JSON_RPC,
        translationScopeSupport: true,
        description: 'Configuration for an external connector that exposes the JSON RPC API.',
      },
    ],
    [
      XillioConnectorType.LIFERAY,
      {
        configurationType: XillioConnectorType.LIFERAY,
        translationScopeSupport: false,
        description:
          'Digital Experience Software Tailored to Your Needs: Build portals, intranets, websites and connected experiences on the most flexible platform around.',
      },
    ],
    [
      XillioConnectorType.NUXEO,
      {
        configurationType: XillioConnectorType.NUXEO,
        translationScopeSupport: false,
        description:
          'Nuxeo makes your content accessible to your entire organization while also keeping it safe. Better yet: we do it without interrupting your existing work processes.',
      },
    ],
    [
      XillioConnectorType.ONE_DRIVE,
      {
        configurationType: XillioConnectorType.ONE_DRIVE,
        translationScopeSupport: false,
        description:
          'OneDrive is Microsoft\'s service for hosting files in the "cloud", that\'s available for free to all the owners of a Microsoft account and offers users a simple way to store, sync and share all kinds of files, with other people and devices.',
      },
    ],
    [
      XillioConnectorType.OPENTEXT,
      {
        configurationType: XillioConnectorType.OPENTEXT,
        translationScopeSupport: false,
        description:
          'OpenText Content Server provides the core set of tools you need to capture, process and manage unstructured content across your organization including document management, workflow, search and information retrieval services, all tightly integrated into a platform that is easily customized and extended.',
      },
    ],
    [
      XillioConnectorType.S3,
      {
        configurationType: XillioConnectorType.S3,
        translationScopeSupport: false,
        description:
          'S3 is a storage service offered by Amazon and other vendors. It stands for simple storage service and provides cloud storage for various types of web development applications.',
      },
    ],
    [
      XillioConnectorType.SALESFORCE,
      {
        configurationType: XillioConnectorType.SALESFORCE,
        translationScopeSupport: true,
        description: 'Salesforce Knowledge is part of complex cloud-based CRM solution.',
      },
    ],
    [
      XillioConnectorType.SHARE_POINT,
      {
        configurationType: XillioConnectorType.SHARE_POINT,
        translationScopeSupport: false,
        description: 'SharePoint is web-based, collaborative platform that integrates with Microsoft Office.',
      },
    ],
    [
      XillioConnectorType.TRIM,
      {
        configurationType: XillioConnectorType.TRIM,
        translationScopeSupport: false,
        description:
          'Automate secure content and records management, from creation to disposal, to regain control of your assets and mitigate risk. Previously known as HP Content Manager and HP Trim.',
      },
    ],
    [
      XillioConnectorType.SITECORE,
      {
        configurationType: XillioConnectorType.SITECORE,
        translationScopeSupport: false,
        description:
          'Sitecore is a CMS with additional marketing functionality that analyses customer data from multiple sources and makes it possible to show custom content.',
      },
    ],
    [
      XillioConnectorType.WORDPRESS,
      {
        configurationType: XillioConnectorType.WORDPRESS,
        translationScopeSupport: true,
        description:
          'WordPress is a free and open-source content management system (CMS). Features include a plugin architecture and a template system.',
      },
    ],
    [
      XillioConnectorType.ZENDESK,
      {
        configurationType: XillioConnectorType.ZENDESK,
        translationScopeSupport: true,
        description: 'Zendesk Guide is a smart knowledge base system for better self-service and empowered agents.',
      },
    ],
  ]);

  public static getAll(): XillioConnector[] {
    return Array.from(XillioConnectors.connectors.values());
  }

  public static supportsTranslationScope(type: XillioConnectorType): boolean {
    if (!XillioConnectors.connectors.has(type)) {
      throw new Error(`Unsupported Connector type: ${type}"`);
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return XillioConnectors.connectors.get(type)!.translationScopeSupport;
  }
}
