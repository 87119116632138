import { TaskStatus } from 'components/Task/TaskStatus/TaskStatus';
import { Task } from 'modules/api/locHub/tasks/task/task';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Translatable } from '../../../../common/language';
import { HtmlProps } from '../../../../common/utils/HTMLProps';
import { Box } from '../../../../components/Box/Box';
import { Columns } from '../../../../components/Columns/Columns';
import { Property } from '../../../../components/Property/Property';

export interface Props extends HtmlProps, Translatable {
  task: Task;
}

export const Metadata: React.FC<Props> = ({ i18nKey, className, id, task }): ReactElement => {
  const [t] = useTranslation();
  return (
    <Box i18nKey={i18nKey} className={className} id={id}>
      {task ? (
        <Columns>
          <Property i18nKey={`${i18nKey}.nameProperty`} icon="fa-tag">
            {task.name}
          </Property>
          <Property i18nKey={`${i18nKey}.statusProperty`} icon="">
            <TaskStatus taskStatus={task.status} taskError={task.error} />
          </Property>
          <Property i18nKey={`${i18nKey}.createdProperty`} icon="fa-calendar-day">
            {t('locale.date', { date: new Date(task.createdAt) })}
          </Property>
          <Property i18nKey={`${i18nKey}.modifiedProperty`} icon="fa-clock">
            {t('locale.fromNow', { date: new Date(task.updatedAt) })}
          </Property>
        </Columns>
      ) : (
        <div className="has-text-centered">
          <i className="fas fa-spin fa-spinner is-centered" data-testid="Overview.TaskInfo.Page.loader" />
        </div>
      )}
    </Box>
  );
};
